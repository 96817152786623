import React, { useState, useEffect } from "react";
import { useEffectOnce, useWindowSize } from "react-use";
import Slider from "react-slick";
import styled from "styled-components/macro";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import useFocusedInput from "hooks/useFocusedInput";
import errorHandler from "api/errorHandler";
import placeholderImage from "assets/placeholder-image.png";
import { ReactComponent as Logo } from "assets/logo-coupang-white.svg";
import Modal from "components/Modal";

import { initFormData } from "CPFRecruit/apis/applicantAPI";

import ApplicantForm from "./ApplicantForm";
import SuccessModal from "./SuccessModal";

const SimpleRecruitPage = () => {
  const { height: windowHeight } = useWindowSize();
  const [images, setImages] = useState([placeholderImage]);
  const [showSubmitComplete, setShowSubmitComplete] = useState(false);
  const [driverLicenses, setDriverLicenses] = useState([]);
  const focusedInput = useFocusedInput();

  useEffect(() => {
    if (focusedInput) {
      focusedInput.scrollIntoView({ block: "center" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowHeight]);

  useEffectOnce(() => {
    async function init() {
      try {
        const { carouselImagePaths, driverLicenseTypes } = await initFormData();
        setImages(carouselImagePaths);
        setDriverLicenses(
          driverLicenseTypes.map(({ type, name }) => ({
            value: type,
            label: name,
          }))
        );
      } catch (error) {
        errorHandler(error);
      }
    }
    init();
  });
  const openCompleteModal = () => setShowSubmitComplete(true);
  const closeCompleteModal = () => setShowSubmitComplete(false);

  return (
    <Background style={{ height: windowHeight }}>
      <Wrapper>
        <Header>
          <Logo /> 쿠팡친구
        </Header>
        <Main>
          <StyledSlider arrows={false} dots autoplay>
            {images.map((image, index) => (
              <img key={index} src={image} alt="banner" />
            ))}
          </StyledSlider>
          <ApplicantForm
            driverLicenses={driverLicenses}
            onSubmit={openCompleteModal}
          />
        </Main>
      </Wrapper>
      <Modal
        label="successful"
        isOpen={showSubmitComplete}
        onOverlayClick={closeCompleteModal}
      >
        <SuccessModal onClose={closeCompleteModal} />
      </Modal>
    </Background>
  );
};

const Background = styled.div`
  background: #e5efff;
  min-height: -webkit-fill-available;
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 640px;
  margin: 0 auto;
  height: 100%;
  background: #ffffff;
  overflow: hidden;
`;

const Main = styled.main`
  height: 100%;
  overflow: scroll;
`;

const Header = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 55.73%,
    rgba(0, 0, 0, 0.21) 100%
  );
  display: flex;
  padding: 16px 20px;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  line-height: 1;
  z-index: 1;

  svg {
    margin-right: 8px;
  }
`;

const StyledSlider = styled(Slider)`
  img {
    height: 330px;
    display: block !important;
    object-fit: cover;
  }
  .slick-dots {
    bottom: 12px;
    li {
      height: auto;
      width: auto;
      margin: 0 2px;
      button {
        background: #ffffff80;
        height: 4px;
        width: 4px;
        border-radius: 2px;
        padding: 0;
      }
    }
    li.slick-active button {
      width: 16px;
      background: #ffffff;
    }
    button::before {
      display: none;
    }
  }
`;

export default SimpleRecruitPage;
