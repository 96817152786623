import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components/macro";
import { ToastContainer, Slide } from "react-toastify";

const Toast = styled(ToastContainer).attrs({
  // custom props
  transition: Slide,
})`
  .Toastify__toast-container {
    width: 100%;
  }
  .Toastify__toast {
    margin-top: 30px;
  }
  .Toastify__toast--error {
    border: 1px solid #ebeef5;
    padding: 15px 15px 15px 20px;
    background-color: #fef0f0;
    border-color: #fde2e2;
    color: #f56c6c;
    opacity: 0.9;
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

export default Toast;
