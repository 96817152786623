import React from "react";

import pinSvg from "assets/pin.svg";

const MapListComponent = ({ areaList = [], onChange = () => {} }) => (
  <ol>
    {areaList.map((rowItem, rIndex) => (
      <li key={rIndex}>
        <input
          type="radio"
          name="radio_box"
          value={rowItem.campId}
          id={"J_Map_" + rIndex}
          onChange={(ev) => onChange(ev.target.value)}
        />
        <label htmlFor={"J_Map_" + rIndex}>
          <svg width="24" height="24">
            <circle cx="12" cy="12" r="10" className="outter"></circle>
            <circle cx="12" cy="12" r="5" className="inner"></circle>
          </svg>
          <span>
            {rowItem.name}({rowItem.addr})
          </span>
        </label>
        <a
          id={"naverMapIframe"}
          href={rowItem.addrUrl.replace("http:", "https:")}
          target={"_blank"}
          rel="noopener noreferrer"
        >
          {" "}
          <img
            src={pinSvg}
            alt=""
            data-lng={rowItem.lng}
            data-lat={rowItem.lat}
          />
        </a>
      </li>
    ))}
  </ol>
);

export default MapListComponent;
