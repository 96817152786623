import BannerImg from "assets/couchin-application-expand.png";
import {
  KAKAO_CHANNEL_LINK,
  OPERATION_PHONE_NUMBER,
} from "CPFRecruit/util/constants";
import React, { useState } from "react";

const Banner = () => {
  const [contactPopupVisible, setContactPopupVisible] = useState(false);

  return (
    <>
      <h2>쿠팡친구에 도전해 보세요!</h2>
      <div className="welfare">
        <div>
          주5일 근무/연차 15일
          <br />
          자녀 보육비 지원(어린이집, 유치원)
          <br />
          차량/유류비 지원
          <br />
          단체실손보험 지원
          <br />
          관공서의 공휴일 대체휴무 지급
          <br />
          배송 역량에 따른 인센티브 <br />
          1년 6개월 근무 시<br />
          캠프관리자 지원가능
          <br />
          쿠팡케어(맞춤형 건강증진프로그램)
          <button
            className="recruit-inquiry"
            onClick={() => setContactPopupVisible(true)}
          >
            ※ 채용 관련 문의
            <div className="contact-icon kakao-icon" />
            <div className="contact-icon call-icon" />
          </button>
        </div>
        <img src={BannerImg} alt="banner" />
      </div>
      {contactPopupVisible && (
        <>
          <div className="contact-popup">
            <button
              className="btn-close"
              onClick={() => setContactPopupVisible(false)}
            />
            <a href={`tel:${OPERATION_PHONE_NUMBER}`}>
              <div className="contact-icon call-icon" />
              전화 문의하기
            </a>
            <a href={KAKAO_CHANNEL_LINK}>
              <div className="contact-icon kakao-icon" />
              카카오톡 문의하기
            </a>
            <div className="contact-message">
              <strong>문의가능 시간</strong>
              <br />
              전화상담 : 09:30 ~ 00:00 <br />
              카카오톡 : 09:30 ~ 00:00 <br />
            </div>
          </div>
          <div className="mask-div" />
        </>
      )}
    </>
  );
};

export default Banner;
