import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import RedirectToRoot from "components/RedirectToRoot";
import Toast from "components/Toast";
import "CPFRecruit/App.css";
import "CPFRecruit/styles/index.scss";

import { trackPageVisit } from "CPFRecruit/util/analytics";

import LandingPage from "CPFRecruit/pages/Landing/LandingPage";
import AdditionalInfoPage from "CPFRecruit/pages/AdditionalInfo/AdditionalInfoPage";
import TermsPage from "CPFRecruit/pages/Terms/TermsPage";
import SimpleRecruitPage from "CPFRecruit/pages/SimpleRecruit/SimpleRecruitPage";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/index" component={LandingPage} />
          <Route path="/tc" component={TermsPage} />
          <Route path="/preferItem" component={AdditionalInfoPage} />
          <Route path="/:landingName" component={LandingPage} />
          <Route component={RedirectToRoot} />
        </Switch>
      </div>
    </Router>
  );
};

const SimpleRecruitApp = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={SimpleRecruitPage} />
        <Route path="/tc" component={TermsPage} />
        <Route component={RedirectToRoot} />
      </Switch>
      <Toast
        position="top-center"
        limit={1}
        autoClose={2000}
        closeOnClick
        hideProgressBar
      />
    </Router>
  );
};

// if (process.env.NODE_ENV === "development") {
//   const { worker } = require("CPFRecruit/apis/mocks/browser");
//   worker.start();
// }

const ctag = window.location.href.match(/ctag=([^&#]*)/i)?.[1] || "";
const SIMPLE_RECRUIT_ENTRY_KEY = "simple-recruit";

const root = document.getElementById("root");
if (ctag === SIMPLE_RECRUIT_ENTRY_KEY) {
  ReactDOM.render(<SimpleRecruitApp />, root);
} else {
  trackPageVisit();
  ReactDOM.render(<App />, root);
}
