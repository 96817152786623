import React from "react";
import { useHistory, useLocation } from "react-router-dom";

const TermsPage = () => {
  const location = useLocation();
  const router = useHistory();

  const returnToIndex = () => {
    const params = location.search;
    router.push("/" + params);
  };

  return (
    <div className="tc-page">
      <div>
        <p onClick={returnToIndex}>
          <i className="icon-left" /> 상세보기
        </p>
      </div>
      <h5>개인정보 수집 및 이용에 대한 안내</h5>
      쿠팡은 쿠팡친구 지원을 위해 최소한의 개인정보를.
      <br />
      처리하고 있으며, 사전 동의 없이는 목적 외로 이용하
      <br />
      지 않습니다
      <br />
      <br />
      1. 수집하는 항목 : 이름, 연락처, 출생년도, 거주지 주소(읍/면/동),
      운전면허종류
      <br />
      2. 개인정보 수집 이용 목적 : 쿠팡친구 인재채용
      <br />
      3. 개인정보의 보유 및 이용 기간 :{" "}
      <b>
        입사 지원서에 작<br />
        &nbsp; &nbsp; &nbsp;성하신 개인정보는 회사의 인재 채용을 위한 인재
        <br />
        &nbsp; &nbsp; &nbsp;풀로 활용될 예정으로{" "}
        <strong>채용전형 결과 통보일로부터</strong>
        <br />
        &nbsp; &nbsp; &nbsp;<strong>1년까지</strong> 보관됩니다. 지원자께서
        삭제를 요청하실
        <br />
        &nbsp; &nbsp; &nbsp;경우 해당 개인정보는 삭제됩니다.
      </b>
      <br />
      *귀하는 위 개인정보 수집 및 이용 동의를 거절할 수 있습니다. 다만, 거절할
      경우 입사 지원이 불가합니다.
      <span
        onClick={() => {
          window.open(
            "https://rocketyourcareer.kr.coupang.com/privacy-policy",
            "_blank"
          );
        }}
      >
        쿠팡 입사지원자 개인정보처리방침 <i />
      </span>
    </div>
  );
};

export default TermsPage;
