import React from "react";

const CouInput = (props) => {
  const label = props.label;
  const placeholder = props.placeholder;

  return (
    <div className={`CouInput ${props.invalid ? "invalid-input" : ""}`}>
      <label>{label}</label>
      <input
        placeholder={placeholder}
        value={props.value || ""}
        onChange={(ev) => {
          props.onChange && props.onChange(ev.target.value);
        }}
        onBlur={(ev) => {
          props.onBlur && props.onBlur(ev.target.value);
        }}
        onFocus={(ev) => {
          props.onFocus && props.onFocus(ev.target.value);
        }}
      />
    </div>
  );
};

export default CouInput;
