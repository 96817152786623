import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

const RedirectToRoot = ({ location }) => {
  useEffect(() => {
    // TODO: Replace console.log with actual logger
    console.log(`Unknown Route: ${location.pathname}${location.search}`);
  }, [location]);
  return <Redirect to={`/${location.search}`} />;
};

export default RedirectToRoot;
