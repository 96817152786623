import { toast } from "react-toastify";

const defaultErrorMessage = "에러가 발생했습니다.";

export class ApiError extends Error {
  constructor(message) {
    super(message || defaultErrorMessage);
    this.name = "ApiError";
  }
}

function errorHandler(error) {
  if (error instanceof ApiError) {
    toast.error(error.message);
    return;
  }
  // TODO: Log unexpected error
  toast.error(defaultErrorMessage);
}

export default errorHandler;
