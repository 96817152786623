import React, { useState } from "react";

const CouSelect = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [label, setLable] = useState("");

  const dataList = props.data || [];

  return (
    <dl className={props.label ? "CouSelect" : "CouSelect emptyLabel"}>
      <dt
        tabIndex="1"
        onClick={(ev) => {
          setOpen(true);
        }}
        onBlur={(ev) => {
          setOpen(false);
        }}
      >
        {Boolean(props.label) && <label>{props.label}</label>}
        {value === "" ? (
          <span className="placeholder">{props.placeholder || ""}</span>
        ) : (
          <span>{label}</span>
        )}
        <svg width="12" height="11"></svg>
      </dt>
      <dd
        style={{
          height:
            dataList.length * 60 > 480 ? "480px" : dataList.length * 60 + "px",
          display: open ? "block" : "none",
        }}
      >
        <div>
          {dataList.length ? (
            <ol>
              {dataList.map((item, index) => (
                <li
                  data-value={item.value}
                  className={value === item.value ? "active" : ""}
                  key={index}
                  onMouseDown={(ev) => {
                    let placeholder = props.placeholder || "";
                    if (item.label !== placeholder) {
                      setValue(item.value);
                      setLable(item.label);
                    } else {
                      setValue("");
                      setLable("");
                    }

                    props.onChange && props.onChange(item.value);
                  }}
                >
                  {item.label}
                </li>
              ))}
            </ol>
          ) : (
            <ol>
              <li style={{ textAlign: "center" }}>No data</li>
            </ol>
          )}
        </div>
      </dd>
    </dl>
  );
};

export default CouSelect;
