import React from "react";
import styled from "styled-components/macro";

import { ReactComponent as Close } from "assets/close-white.svg";
import { ReactComponent as Check } from "assets/check.svg";
import { ReactComponent as KakaoChannel } from "assets/kakao-channel.svg";
import { ReactComponent as Phone } from "assets/phone-white.svg";

import {
  KAKAO_CHANNEL_LINK,
  OPERATION_PHONE_NUMBER,
} from "CPFRecruit/util/constants";

const SuccessModal = ({ onClose }) => (
  <ModalBody>
    <CloseButton aria-label="close" onClick={onClose}>
      <Close />
    </CloseButton>
    <ProcessDescription>
      <Marker>
        <BlueCircle>
          <Check />
        </BlueCircle>
        <BlueLine />
        <GreenCircle />
        <DottedLine>
          {[...Array(8)].map((_, i) => (
            <div key={i} />
          ))}
        </DottedLine>
        <GrayCircle />
      </Marker>
      <Description>
        <p>간편지원 완료</p>
        <p>업무 교육 및 체험</p>
        <p>
          카카오톡으로
          <br />곧 안내 드릴게요.
        </p>
        <p>쿠팡친구 합류</p>
      </Description>
    </ProcessDescription>
    <LinkSection>
      <KakaoChannelLink href={KAKAO_CHANNEL_LINK}>
        <KakaoChannel />
        채용팀 카카오톡 채널 추가하기
      </KakaoChannelLink>
      <CallLink href={`tel:${OPERATION_PHONE_NUMBER}`}>
        <Phone />
        채용팀 전화 문의하기
      </CallLink>
    </LinkSection>
  </ModalBody>
);

const ModalBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 264px;
  height: 376px;
  padding: 60px 24px 28px;
  background: linear-gradient(180deg, #346aff 62.5%, #2e8eff 100%);
  box-shadow: 1px 6px 17px rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  border-radius: 24px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 22px;
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
`;

const ProcessDescription = styled.section`
  display: flex;
  padding-left: 20px;
`;
const Marker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CircleStyle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-sizing: border-box;
`;
const BlueCircle = styled(CircleStyle)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  background: #0f3294;
`;
const BlueLine = styled.div`
  width: 2px;
  height: 32px;
  background: #0f3294;
`;
const GreenCircle = styled(CircleStyle)`
  width: 20px;
  height: 20px;
  background: linear-gradient(180deg, #1eff93 52.08%, #4dd4ff 100%);
  border: 2px solid #ffffff;
`;
const DottedLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2px;
  height: 74px;
  > div {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: #ffffff;
  }
`;
const GrayCircle = styled(CircleStyle)`
  opacity: 0.6;
  border: 2px solid #fffefe;
`;

const Description = styled.div`
  margin-left: 11px;
  > p {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    &:nth-of-type(1) {
      opacity: 0.6;
    }
    &:nth-of-type(2) {
      margin-top: 24px;
      font-size: 22px;
    }
    &:nth-of-type(3) {
      margin-top: 4px;
      font-size: 14px;
    }
    &:nth-of-type(4) {
      margin-top: 24px;
      opacity: 0.6;
    }
  }
`;

const LinkSection = styled.section`
  margin-top: auto;
`;

const LinkStyle = styled.a`
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: 0;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  > svg {
    margin-right: 4px;
  }
`;

const KakaoChannelLink = styled(LinkStyle)`
  background: #ffffff;
  color: #346aff;
`;
const CallLink = styled(LinkStyle)`
  margin-top: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #ffffff;
`;

export default SuccessModal;
