const noop = (...args) => {
  if (
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_BUILD_PROFILE === "develop"
  ) {
    console.log(args);
  }
};
const fbq = window.fbq || noop;
const gtag = window.gtag || noop;
const kakaoPixel = window.kakaoPixel || noop;
const kakaoPixelTrackingID = "5590892233281614300";
const kakaoPixelAPI = kakaoPixel(kakaoPixelTrackingID) || {
  pageView: () => {},
  participation: () => {},
  signUp: () => {},
};

export function trackPageVisit() {
  kakaoPixelAPI.pageView();
}

export function trackLead() {
  fbq("track", "Lead");
  gtag("event", "Lead");
  gtag("event", "conversion", {
    send_to: "AW-328857210/eVMWCNfP1t0CEPrs55wB",
  });
  kakaoPixelAPI.participation();
}

export function trackCompleteRegistration() {
  fbq("track", "CompleteRegistration");
  gtag("event", "CompleteRegistration");
  gtag("event", "conversion", {
    send_to: "AW-328857210/mqk_CIH20N0CEPrs55wB",
  });
  kakaoPixelAPI.signUp();
}

export function googleEventTracker(event, actionParam, labelParam) {
  if (event === undefined) return;
  let action = event.action;
  if (actionParam !== undefined) {
    action = action.replace("?", actionParam);
  }

  let label = event.label;
  if (label !== undefined && labelParam !== undefined) {
    label = label.replace("?", labelParam);
  }

  gtag("event", action, {
    send_to: "G-KT5QYQVCS8",
    event_category: event.category,
    event_label: label,
  });
}

//
export const cpfEvents = {
  onFocusName: {
    category: "Form Fill",
    action: "Click on Name Field",
  },
  onFocusPhone: {
    category: "Form Fill",
    action: "Click on Phone Field",
  },
  onClickLocation: {
    category: "Form Fill",
    action: "Click on Location Field",
  },
  onClickLocationDetailField: {
    category: "Location Detail Page",
    action: "Click on Location Input Field",
  },
  onSelectLocationDetail: {
    category: "Location Detail Page",
    action: "Select Location from displayed list",
  },
  onCloseLocationDetailPage: {
    category: "Location Detail Page",
    action: "Close Location Detail Page",
  },
  onChangeLicense: {
    category: "Form Fill",
    action: "Click on License Field",
  },
  onFocusBirthYear: {
    category: "Form Fill",
    action: "Click on YearofBirth Field",
  },
  onFocusStartDate: {
    category: "Form Fill",
    action: "Click on StartingDate Field",
  },
  onClickSubmitButton: {
    category: "Submit",
    action: "Click on Submit Button with ? fields",
    label: "{?}",
  },
};
