import React from "react";
import styled from "styled-components/macro";
import NumberFormat from "react-number-format";

import { ReactComponent as ClearIcon } from "assets/clear-icon.svg";

const FormikInput = ({
  field, // { name, value, onChange, onBlur }
  form: { setFieldValue },
  Icon,
  width,
  disabled,
  rtl,
  placeholder,
  numberFormat,
  onFocus,
  onKeyDown,
  innerRef,
  maxLength,
  tabIndex = "0",
}) => {
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
  };

  const isMaxLength = ({ value }) => {
    if (maxLength === undefined) return true;
    return value.length <= Number(maxLength);
  };

  return (
    <InputWrapper width={width} disabled={disabled} rtl={rtl}>
      {Icon ? <Icon /> : null}
      {numberFormat ? (
        <NumberFormat
          {...field}
          placeholder={placeholder}
          format={numberFormat}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          getInputRef={innerRef}
          tabIndex={tabIndex}
          isAllowed={isMaxLength}
          onKeyPress={handleKeyPress}
          autoComplete="off"
        />
      ) : (
        <input
          {...field}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          ref={innerRef}
          tabIndex={tabIndex}
          onKeyPress={handleKeyPress}
          autoComplete="off"
        />
      )}
      {disabled ? null : (
        <ClearButton
          aria-label={`clear-${field.name}`}
          type="button"
          onMouseDown={(e) => {
            e.preventDefault();
            setFieldValue(field.name, "");
          }}
        >
          <ClearIcon />
        </ClearButton>
      )}
    </InputWrapper>
  );
};

const ClearButton = styled.button`
  position: absolute;
  right: 10px;
  visibility: hidden;
  background: transparent;
  height: 16px;
  width: 16px;
  overflow: hidden;
  padding: 0;
  border: 0;
  outline: 0;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 44px;
  width: ${({ width }) => width || "100%"};
  background: ${({ disabled }) => (disabled ? "#f9fafb" : "transparent")};
  border: 1px solid ${({ disabled }) => (disabled ? "#dfe3e8" : "#c4cdd5")};
  border-radius: 8px;
  box-sizing: border-box;
  input {
    height: 100%;
    width: 100%;
    padding: 10px 28px 10px 14px;
    box-sizing: border-box;
    outline: none;
    border: 0;
    caret-color: #346aff;
    font-size: 16px;
    color: #212b36;
    direction: ${({ rtl }) => (rtl ? "rtl" : "ltr")};
    @media (min-width: 420px) {
      direction: ltr;
    }
    &:disabled {
      opacity: 1;
      background: transparent;
    }
    &::placeholder {
      color: #aab5c0;
    }
  }
  > svg {
    margin-left: 14px;
    flex-shrink: 0;
    & + input {
      padding-left: 10px;
    }
  }
  &:focus-within {
    border: 1px solid #346aff;
    box-shadow: 0 0 0 2px #eaf0ff;
    ${ClearButton} {
      visibility: visible;
    }
  }
`;

export default FormikInput;
