import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { useSpring, animated } from "react-spring";
import styled from "styled-components/macro";

const Modal = ({ children, label, isOpen, onOverlayClick }) => {
  const el = useRef(document.createElement("div"));
  const [wrapperStyle, api] = useSpring({ opacity: 0 }, []);
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.appendChild(el.current);
      api.start({ opacity: 1 });
    } else {
      document.body.style.overflow = "auto";
      if (document.body.contains(el.current)) {
        api.start({
          to: async (next) => {
            await next({ opacity: 0 });
            document.body.removeChild(el.current);
          },
        });
      }
    }
  }, [api, isOpen]);

  return ReactDOM.createPortal(
    <Wrapper aria-label={label} style={wrapperStyle}>
      <Overlay data-testid="modal-overlay" onClick={onOverlayClick} />
      <Body>{children}</Body>
    </Wrapper>,
    el.current
  );
};

const Wrapper = styled(animated.dialog)`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.3;
`;

const Body = styled.div`
  z-index: 1;
`;

export default Modal;
