import React, { useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Http, { showErrorMessage } from "CPFRecruit/util/http";
import { trackLead } from "CPFRecruit/util/analytics";

import HireHeader from "CPFRecruit/components/HireHeader";
import Banner from "CPFRecruit/components/Banner";
import CouInput from "CPFRecruit/components/CouInput";

const LandingPage = () => {
  const [name, setName] = useState(sessionStorage.getItem("name") || "");
  const [phone, setPhone] = useState(sessionStorage.getItem("phone") || "");

  const history = useHistory();
  const location = useLocation();
  const saveRef = useRef();

  let doubleCheck = false;

  const jumpToTc = () => {
    const params = location.search;
    history.push("/tc" + params);
  };

  const saveNameAndPhone = async () => {
    if (!saveRef.current.classList.contains("btn-disabled")) {
      trackLead();
      if (doubleCheck) {
        return;
      }
      doubleCheck = true;
      const params = location.search;
      sessionStorage.setItem("params", params);

      const landing = location.search.match(/ctag=([^&#]*)/i)?.[1] || "";
      sessionStorage.setItem("landing", landing);

      const body = {
        params: params.substring(1),
        name,
        phone: phone.replace(/-/g, ""),
      };
      const { errMsg, data } = await Http.post("/cpf/applicant/save", body);

      if (errMsg == null) {
        sessionStorage.setItem("token", data);
        history.push("/preferItem");
      } else {
        showErrorMessage(errMsg);
      }
    }
  };

  const enableSaveButtonStatus = () => {
    return name !== "" && phone !== "" && /^\d{3}-\d{4}-\d{3,4}$/.test(phone);
  };

  return (
    <>
      <HireHeader />
      <div className="index-page">
        <Banner />
        <div className={`person-div`}>
          <div className="divide-area" />
          <div className="person-info">
            <h4>지원자 정보</h4>

            <CouInput
              label={"성함"}
              value={name}
              placeholder={"성함 입력"}
              onChange={(value) => {
                setName(value);
                sessionStorage.setItem("name", value);
              }}
            />
            <CouInput
              label={"연락처"}
              placeholder={"휴대폰 번호 입력 (숫자만 입력)"}
              value={phone}
              onChange={(value) => {
                setPhone(value);
                sessionStorage.setItem("phone", value);
                if (value.trim().length > phone.length) {
                  const phoneStartPattern = /^(\d{3})$/;
                  const phoneMiddlePattern = /^(\d{3})-(\d{4})$/;
                  if (
                    phoneStartPattern.test(value.trim()) ||
                    phoneMiddlePattern.test(value.trim())
                  ) {
                    setPhone(value.trim() + "-");
                  }
                }
              }}
            />

            <div className="tc-area">
              쿠팡그룹의 정책에 따라 입사지원 시 지원자의 개인정보를 받고
              있습니다.
              <div>
                <p
                  onClick={() => {
                    jumpToTc();
                  }}
                >
                  {" "}
                  자세히 보기 <i className="icon-right" />
                </p>
              </div>
            </div>
            <div className="submit-person">
              <button
                ref={saveRef}
                className={enableSaveButtonStatus() ? "" : "btn-disabled"}
                onClick={saveNameAndPhone}
              >
                동의하고 지원하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
