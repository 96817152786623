import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import Http, { showErrorMessage } from "CPFRecruit/util/http";
import { trackCompleteRegistration } from "CPFRecruit/util/analytics";

import HireHeader from "CPFRecruit/components/HireHeader";
import Banner from "CPFRecruit/components/Banner";
import CouSelect from "CPFRecruit/components/CouSelect";
import CouInput from "CPFRecruit/components/CouInput";

import MapListComponent from "./MapListComponent";

const driverLicenseList = [
  { value: "1종보통" },
  { value: "1종대형" },
  { value: "2종수동" },
  { value: "2종자동" },
  { value: "미보유" },
];

const AdditionalInfoPage = () => {
  const [areaList, setAreaList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [preferredArea, setPreferredArea] = useState("");
  const [preferredCampCode, setPreferredCampCode] = useState("");
  const [preferredDate, setPreferredDate] = useState(
    sessionStorage.getItem("preferredDate") || ""
  );
  const [preferredDateValid, setPreferredDateValid] = useState(
    !isInvalidPreferredDate(preferredDate)
  );
  const [driverLicense2, setDriverLicense2] = useState("");
  const [birthday, setBirthday] = useState("");
  const [birthValid, setBirthValid] = useState(true);
  const [showCompleteMessage, setShowCompleteMessage] = useState(false);

  const submitRef = useRef();
  const history = useHistory();

  useEffect(() => {
    if (
      sessionStorage.getItem("params") == null ||
      sessionStorage.getItem("landing") == null
    ) {
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("phone");

      history.push("/");
      return;
    }

    (async () => {
      const landingName = sessionStorage.getItem("landing") || "CPFFULL";

      const {
        data: { areaList, errMsg },
      } = await Http.get(
        "/cpf/applicant/config/landing?landing=" + landingName
      );
      if (!errMsg) {
        setAreaList(areaList || []);
      }
    })();
  }, [history]);

  const enableSubmitButtonStatus = () => {
    return (
      preferredArea !== "" &&
      preferredCampCode !== "" &&
      preferredDate !== "" &&
      /^(20\d{2}-((0[1-9])|(1[0-2]))-(0[1-9]|[12][0-9]|3[01]))$/.test(
        preferredDate.trim()
      ) &&
      driverLicense2 !== "" &&
      birthday.length === 10 &&
      birthValid
    );
  };

  const submit = async () => {
    if (!submitRef.current.classList.contains("btn-disabled")) {
      const body = {
        token: sessionStorage.getItem("token"),
        name: sessionStorage.getItem("name"),
        phone: sessionStorage.getItem("phone"),
        driverLicense2: driverLicense2,
        groupId: preferredArea,
        campId: preferredCampCode,
        interviewTimeId: preferredDate.replace(/-/g, ""),
        birthdayYMD: birthday.replace(/-/g, ""),
      };

      const { errMsg } = await Http.post("/cpf/applicant/save", body);

      if (!errMsg) {
        trackCompleteRegistration();
        setShowCompleteMessage(true);
        return;
      }
      showErrorMessage(errMsg);
    }
  };

  return (
    <>
      <HireHeader />
      <div className="index-page">
        <Banner />
        <div className="prefer-div">
          <div className="divide-area" />
          <div className="work-info">
            <h4>운전면허 보유 확인</h4>
            <div>
              <CouSelect
                placeholder={"운전면허 유형 선택하세요"}
                label={"운전면허 보유 확인"}
                data={driverLicenseList.map((item) => ({
                  label: item.value,
                  value: item.value,
                }))}
                onChange={setDriverLicense2}
              />
            </div>
            <div className={`prefer-description`}>
              운전면허 보유자만 지원 가능해요.
            </div>
            <h4>희망 근무 지역</h4>
            <div>
              <CouSelect
                placeholder={"희망 근무 지역을 선택하세요"}
                label={"희망 근무 지역"}
                data={areaList.map((item) => ({
                  label: item.groupName,
                  value: item.groupId,
                }))}
                onChange={(value) => {
                  setPreferredArea(value);
                  areaList.forEach((area) => {
                    if (area.groupId === value) {
                      setLocationList(area.list);
                    }
                  });
                }}
              />
            </div>
            <div className={`prefer-description`}>
              거주지와 가까운 지역으로 선택해 주세요.
            </div>
            <div className="map-box">
              <h4>면접(근무) 장소 및 일정</h4>
              {preferredArea === "" ? (
                <div className="map-tip">
                  희망 근무 지역을 먼저 선택해 주세요.
                </div>
              ) : (
                <MapListComponent
                  areaList={locationList}
                  onChange={setPreferredCampCode}
                />
              )}
            </div>
            <div className={`prefer-period`}>
              <CouInput
                label={"방문 가능 날짜"}
                value={preferredDate}
                invalid={!preferredDateValid}
                placeholder={"YYYY-MM-DD 미래 날짜만 입력 가능합니다"}
                onChange={(value) => {
                  setPreferredDate(value);
                  sessionStorage.setItem("preferredDate", value);
                  const yearPattern = /^(20\d{2}){1}$/;
                  const yearMonthPattern =
                    /^(20\d{2}-((0[1-9]{1})|(1[0-2]{1}))){1}$/;
                  if (value.trim().length > preferredDate.length) {
                    if (yearPattern.test(value.trim())) {
                      setPreferredDate(value.trim() + "-");
                    }
                    if (yearMonthPattern.test(value.trim())) {
                      setPreferredDate(value.trim() + "-");
                    }
                  }
                }}
                onBlur={(date) => {
                  if (isInvalidPreferredDate(date)) {
                    setPreferredDateValid(false);
                  } else {
                    setPreferredDateValid(true);
                  }
                }}
                onFocus={() => {
                  setPreferredDateValid(true);
                }}
              />
              <CouInput
                label="생년월일"
                placeholder="YYYY-MM-DD"
                value={birthday}
                invalid={!birthValid}
                onChange={(val) => {
                  let value = val.trim();
                  const inputYear = /^\d{4}$/.test(value);
                  const inputMonth = /^\d{4}-\d{2}$/.test(value);

                  if (
                    value.length > birthday.length &&
                    (inputYear || inputMonth)
                  ) {
                    value += "-";
                  }
                  setBirthday(value);
                }}
                onBlur={(value) => setBirthValid(validateBirthdayText(value))}
                onFocus={() => setBirthValid(true)}
              />
            </div>
            <div className="submit-person">
              <button
                ref={submitRef}
                className={enableSubmitButtonStatus() ? "" : "btn-disabled"}
                onClick={submit}
              >
                추가정보 입력완료
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCompleteMessage && (
        <>
          <div className="complete-popup">
            <div className="success-area">
              <div className="success-mark">
                <i width="28" height="28" />
              </div>
              <div className="message-content">
                <div className="message">
                  <div className="normal-message">
                    지원서 제출이
                    <br />
                    완료되었습니다.
                  </div>
                </div>
                <div className="reason">
                  자세한 면접 일정은
                  <br />
                  인사담당자께서
                  <br />
                  개별적으로 연락드릴 예정입니다.
                  <br />
                </div>
              </div>
              <div className="btn-submit-outer">
                <div className="success-button">
                  <button
                    type="button"
                    className="btn-submit"
                    onClick={() => {
                      sessionStorage.removeItem("name");
                      sessionStorage.removeItem("phone");
                      sessionStorage.removeItem("preferredArea");
                      sessionStorage.removeItem("preferredCampCode");
                      sessionStorage.removeItem("preferredDate");
                      sessionStorage.removeItem("preferredPeriod");
                      const params = sessionStorage.getItem("params");
                      history.push("/" + params);
                    }}
                  >
                    확인
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mask-div" />
        </>
      )}
    </>
  );
};

function isInvalidPreferredDate(date) {
  return (
    date !== "" &&
    (!/^(20\d{2}-((0[1-9])|(1[0-2]))-(0[1-9]|[12][0-9]|3[01]))$/.test(
      date.trim()
    ) ||
      new Date(date + " 00:00:00").getTime() <= new Date().getTime())
  );
}

function validateBirthdayText(input) {
  const matchedTexts = input.match(/^(\d{4})-(\d{2})-(\d{2})$/) || [];
  const [, yearText, monthText, dayText] = matchedTexts;

  const year = parseInt(yearText);
  if (isNaN(year) || year < 1900) return false;

  const month = parseInt(monthText);
  if (isNaN(month) || month < 1 || month > 12) return false;

  const day = parseInt(dayText);
  if (isNaN(day) || day < 1 || day > 31) return false;

  const birthDateTime = new Date(input).getTime();
  return !isNaN(birthDateTime) && birthDateTime < Date.now();
}

export default AdditionalInfoPage;
