const prefix = "/web/v1";

export function showErrorMessage(errMsg) {
  let oDiv = document.createElement("div");
  oDiv.className = "message-error";
  setTimeout(() => {
    oDiv.classList.add("enter");
  }, 500);

  oDiv.innerHTML = errMsg;
  document.body.appendChild(oDiv);

  setTimeout(() => {
    oDiv.classList.remove("enter");
    oDiv.classList.add("leave");
    oDiv.addEventListener("transitionend", () => {
      document.body.removeChild(oDiv);
    });
  }, 5000);
}

const http = {
  async get(url, params = {}) {
    let arr = [];
    if (params && Object.keys(params).length) {
      for (let attr in params) {
        arr.push(attr + "=" + params[attr]);
      }

      url = prefix + url + "?" + arr.join("&");
    } else {
      url = prefix + url;
    }

    let data;
    try {
      data = await fetch(url);
    } catch (e) {
      //
    }

    if (data.ok === false) {
      showErrorMessage(data.statusText);
    }

    return await data.json();
  },
  async post(url, params = {}) {
    url = prefix + url;
    let data;
    try {
      data = await fetch(url, {
        body: JSON.stringify(params),
        method: "POST",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "content-type": "application/json",
        },
      });
    } catch (e) {}

    if (data.ok === false) {
      showErrorMessage(data.statusText);
    }

    return await data.json();
  },
};

export default http;
