import axios from "axios";
import { ApiError } from "./errorHandler";

const validationMiddleware = {
  default: (res) => {
    const { data: resData } = res;
    const { resultCode, message, data } = resData;
    if (resultCode !== "SUCCESS") {
      throw new ApiError(message);
    }
    return data;
  },
  flex: (res) => {
    const { data: resData } = res;
    const { errMsg, data } = resData;
    if (errMsg) {
      throw new ApiError(errMsg);
    }
    return data;
  },
};

function clientFactory(option = "default") {
  const client = axios.create();
  client.interceptors.response.use(validationMiddleware[option]);
  return client;
}

export default clientFactory;
