import clientFactory from "api/clientFactory";

const client = clientFactory();

export const API_URL = {
  INIT_FORM_DATA: "/web/v2/cpf/applicant/init/data",
  SAVE_APPLICANT: "/web/v2/cpf/applicant/save",
};

export async function initFormData() {
  const data = await client.get(API_URL.INIT_FORM_DATA);
  return data;
}

export async function searchAddress({ keyword, pageNo, countPerPage }) {
  const data = await client.get(
    `/web/v2/cpf/applicant/address/search/${keyword}/page/${pageNo}/countPerPage/${countPerPage}`
  );
  return data;
}

export async function saveApplicant(body) {
  const data = await client.post(API_URL.SAVE_APPLICANT, body);
  return data;
}
