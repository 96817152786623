import { useEffect, useState } from "react";

const useFocusedInput = () => {
  const [focusedInput, setFocusedInput] = useState(null);

  const handleFocusIn = (e) => {
    const targetInputs = ["name", "phone", "birthdayYear", "birthdayYmd"];
    if (targetInputs.includes(e.target.name)) {
      setTimeout(() => {
        setFocusedInput(e.target);
      }, 0);
    }
  };

  const handleFocusOut = () => {
    setTimeout(() => {
      setFocusedInput(null);
    }, 0);
  };

  useEffect(() => {
    document.addEventListener("focusin", handleFocusIn);
    document.addEventListener("focusout", handleFocusOut);
    return () => {
      document.removeEventListener("focusin", handleFocusIn);
      document.removeEventListener("focusout", handleFocusOut);
    };
  }, []);

  return focusedInput;
};

export default useFocusedInput;
