import React from "react";
import { Link, useLocation } from "react-router-dom";

import "CPFRecruit/styles/header.scss";
import Logo from "assets/logo-coupang.png";

const HireHeader = () => {
  const location = useLocation();

  return (
    <header className="header-nav">
      <div className="tab">
        <dl className="common-1060 clear">
          <dt>
            <Link to={"/" + location.search}>
              <img alt="logo" width="88" height="20" src={Logo} />
            </Link>
            <span>쿠팡친구 채용</span>
          </dt>
        </dl>
      </div>
    </header>
  );
};

export default HireHeader;
