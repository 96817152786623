import React, { useRef, useEffect, useContext } from "react";
import styled from "styled-components/macro";
import { useWindowSize } from "react-use";
import useMeasure from "react-use-measure";
import mobile from "is-mobile";
import { useSpring, animated } from "react-spring";
import { useDrag } from "@use-gesture/react";
import { ResizeObserver } from "@juggle/resize-observer";

const HEIGHT = "_height";
const IS_COLLAPSED = "_isCollapsed";
const isMobile = mobile();

const ActionSheetContext = React.createContext({
  setBaseHeight: () => {},
  styles: {},
  api: {},
});

const ActionSheet = ({
  label,
  isVisible,
  onClose,
  children,
  isDraggable = true,
  maxWidth,
}) => {
  const { height: windowHeight } = useWindowSize();
  const [bodyRef, { height: measuredBodyHeight }] = useMeasure({
    polyfill: ResizeObserver,
  });
  const bodyHeight = useRef(measuredBodyHeight);
  const initialWindowHeight = useRef(windowHeight);
  const baseY = useRef(windowHeight);
  const draggableSection = useRef("");
  const baseHeights = useRef({});
  const [styles, api] = useSpring(() => ({
    visibility: "hidden",
    overlayOpacity: 0,
    y: baseY.current,
  }));

  useEffect(() => {
    bodyHeight.current = measuredBodyHeight;
  }, [measuredBodyHeight]);

  useEffect(() => {
    if (!isMobile) {
      initialWindowHeight.current = windowHeight;
      api.start({ y: windowHeight - bodyHeight.current, immediate: true });
    }
  }, [api, windowHeight]);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
      baseY.current = initialWindowHeight.current - bodyHeight.current;
      if (baseY.current < initialWindowHeight.current * 0.2) {
        baseY.current = initialWindowHeight.current * 0.2;
      }
      api.start({ visibility: "visible", immediate: true });
      api.start({ overlayOpacity: 0.3, y: baseY.current });
    } else {
      document.body.style.overflow = "auto";
      api.start({
        to: async (next) => {
          await next({ overlayOpacity: 0, y: initialWindowHeight.current });
          await next({ visibility: "hidden", immediate: true });
        },
      });
    }
  }, [api, isVisible]);

  const bind = useDrag(({ down: isDragging, movement: [mx, my] }) => {
    const newY = baseY.current + my;
    const section = draggableSection.current;
    if (!section) {
      if (isDragging) {
        api.start({ y: newY, immediate: true });
      } else {
        api.start({ y: baseY.current });
        if (newY > windowHeight - 100) {
          onClose();
        }
      }
      return;
    }

    if (isDragging) {
      let newHeight = styles[`${section}${IS_COLLAPSED}`].get()
        ? -my
        : baseHeights.current[section] - my;
      if (newHeight < 4) {
        newHeight = 0;
      }
      if (newHeight >= baseHeights.current[section]) {
        newHeight = baseHeights.current[section];
      }

      api.start({
        y: newY,
        [`${section}${HEIGHT}`]: newHeight,
        immediate: true,
      });
      return;
    }

    const currentSectionHeight = styles[`${section}${HEIGHT}`].get();
    const baseSectionHeight = baseHeights.current[section];
    const shouldSectionCollapse = currentSectionHeight < baseSectionHeight / 2;
    const newHeight = shouldSectionCollapse ? 0 : baseSectionHeight;
    baseY.current = windowHeight - measuredBodyHeight + currentSectionHeight;
    if (!shouldSectionCollapse) {
      baseY.current -= baseSectionHeight;
    }
    api.start({
      y: baseY.current,
      [`${section}${HEIGHT}`]: newHeight,
      [`${section}${IS_COLLAPSED}`]: shouldSectionCollapse,
    });
    if (newY > windowHeight - 100) {
      onClose();
    }
  });

  const setBaseHeight = React.useCallback(
    ({ section, sectionHeight, isDraggableSection }) => {
      baseHeights.current = {
        ...baseHeights.current,
        [section]: sectionHeight,
      };
      if (isDraggableSection) {
        draggableSection.current = section;
      }
    },
    []
  );

  const toggleIsCollapsed = (section) => () => {
    if (styles[`${section}${IS_COLLAPSED}`].get()) {
      baseY.current -= baseHeights.current[section];
      api.start({
        y: baseY.current,
        [`${section}${HEIGHT}`]: baseHeights.current[section],
        [`${section}${IS_COLLAPSED}`]: false,
      });
    } else {
      baseY.current += baseHeights.current[section];
      api.start({
        y: baseY.current,
        [`${section}${HEIGHT}`]: 0,
        [`${section}${IS_COLLAPSED}`]: true,
      });
    }
  };

  const getIsCollapsed = (section) => {
    return (
      styles[`${section}${HEIGHT}`]?.get() < baseHeights.current[section] / 2
    );
  };

  return (
    <Wrapper
      aria-label={label || "action-sheet"}
      style={{ visibility: styles.visibility, height: windowHeight }}
    >
      <Overlay
        data-testid={
          label ? `${label}-action-sheet-overlay` : "action-sheet-overlay"
        }
        onClick={onClose}
        style={{ opacity: styles.overlayOpacity }}
      />
      <Body style={{ y: styles.y, maxWidth }}>
        <ActionSheetContext.Provider value={{ setBaseHeight, styles, api }}>
          <div ref={bodyRef}>
            {isDraggable ? (
              <DragHandle data-testid="drag-handle" {...bind()} />
            ) : null}
            {typeof children === "function"
              ? children({ toggleIsCollapsed, getIsCollapsed })
              : children}
          </div>
        </ActionSheetContext.Provider>
      </Body>
    </Wrapper>
  );
};

const Collapsible = ({ section, children, isDraggableSection }) => {
  const [sectionRef, { height: sectionHeight }] = useMeasure({
    polyfill: ResizeObserver,
  });
  const { setBaseHeight, styles, api } = useContext(ActionSheetContext);

  useEffect(() => {
    api.start({ [`${section}${HEIGHT}`]: 0 });
    api.start({ [`${section}${IS_COLLAPSED}`]: true });
  }, [api, section]);

  useEffect(() => {
    setBaseHeight({ section, sectionHeight, isDraggableSection });
  }, [setBaseHeight, section, sectionHeight, isDraggableSection]);

  return (
    <CollapsibleSection style={{ height: styles[`${section}${HEIGHT}`] }}>
      <div ref={sectionRef}>{children}</div>
    </CollapsibleSection>
  );
};

ActionSheet.Collapsible = Collapsible;

const DRAG_HANDLE_HEIGHT = 28;

const Wrapper = styled(animated.dialog)`
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  user-select: none;
`;

const Overlay = styled(animated.div)`
  position: absolute;
  left: 0;
  right: 0;
  height: 150%;
  background-color: #000000;
`;

const Body = styled(animated.div)`
  margin: 0 auto;
  width: 100%;
  height: 150%;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  z-index: 1;
`;

const DragHandle = styled.div`
  display: flex;
  align-items: center;
  width: 36px;
  height: ${DRAG_HANDLE_HEIGHT}px;
  margin: 0 auto;
  touch-action: none;
  &::before {
    content: "";
    width: 100%;
    height: 4px;
    background-color: #cccccc;
    border-radius: 2px;
  }
`;

const CollapsibleSection = styled(animated.section)`
  overflow: hidden;
`;

export default ActionSheet;
