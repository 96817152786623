import errorHandler from "api/errorHandler";
import { ReactComponent as ArrowDown } from "assets/arrow-down.svg";
import { ReactComponent as ArrowRight } from "assets/arrow-right-blue.svg";
import Calendar from "assets/calendar.svg";
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as Pin } from "assets/pin-blue.svg";
import { ReactComponent as PinGray } from "assets/pin-gray.svg";
import ActionSheet from "components/ActionSheet";
import Button from "components/Button";
import FormikInput from "components/FormikInput";
import { saveApplicant } from "CPFRecruit/apis/applicantAPI";
import {
  cpfEvents,
  googleEventTracker,
  trackCompleteRegistration,
} from "CPFRecruit/util/analytics";
import { format, parseISO } from "date-fns";
import { Field, Form, Formik } from "formik";
import { PersistFormikValues } from "formik-persist-values";
import useFocusedInput from "hooks/useFocusedInput";
import mobile from "is-mobile";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation } from "react-router-dom";
import Select, { components } from "react-select";
import styled, { css } from "styled-components/macro";
import * as Yup from "yup";
import AddressSearch from "./AddressSearch";

const isMobile = mobile();

const PERSIST_FORM_KEY = "SIMPLE_RECRUIT_FORM";
const ApplicantForm = ({ driverLicenses = [], onSubmit }) => {
  const location = useLocation();
  const focusedInput = useFocusedInput();
  const [showAreaSearchModal, setShowAreaSearchModal] = useState(false);

  const handleShowSearchModal = (isShow, fcEvent) => {
    setShowAreaSearchModal(isShow);
    if (fcEvent !== undefined) {
      googleEventTracker(fcEvent);
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
        address: { oldNumberTownAddress: "" },
        driverLicenseType: "",
        birthdayYear: "",
        workStartDate: "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().trim().required(),
        phone: Yup.string().min(12).required(),
        address: Yup.object()
          .shape({
            city: Yup.string().trim().required(),
            oldNumberTownAddress: Yup.string().trim().required(),
          })
          .required(),
      })}
      onSubmit={async (formData, { resetForm }) => {
        const body = {
          name: formData.name,
          phone: formData.phone.replace(/-/g, ""),
          address: {
            city: formData.address.city,
            district: formData.address.district,
            town: formData.address.town,
          },
          params: location.search.substring(1),
          driverLicenseType: formData.driverLicenseType || null,
          birthdayYear: formData.birthdayYear
            ? formData.birthdayYear.replace("년", "").substring(0, 4)
            : null,
          workStartDate: formData.workStartDate
            ? format(parseISO(formData.workStartDate), "yyyyMMdd")
            : null,
        };
        try {
          await saveApplicant(body);
          trackCompleteRegistration();
          resetForm();
          onSubmit();
        } catch (error) {
          errorHandler(error);
        }
      }}
    >
      {({ dirty, isValid, values, setFieldValue }) => (
        <StyledForm>
          <PersistFormikValues persistInvalid name={PERSIST_FORM_KEY} />
          <Fieldset>
            <legend>이력서 없이 바로 지원하세요</legend>
            <Section>
              <Field
                name="name"
                placeholder="이름"
                onFocus={() => googleEventTracker(cpfEvents.onFocusName)}
                component={FormikInput}
              />
              <Field
                name="phone"
                placeholder="휴대폰 번호"
                width={`${(180 / 132) * 100}%`}
                component={FormikInput}
                onFocus={() => googleEventTracker(cpfEvents.onFocusPhone)}
                numberFormat={(val) =>
                  // prettier-ignore
                  val.length < 4 ? val :
                  val.length < 8 ? `${val.substring(0, 3)}-${val.substring(3, 7)}` :
                  `${val.substring(0, 3)}-${val.substring(3, 7)}-${val.substring(7, 11)}`
                }
              />
            </Section>
            {values.address && values.address.oldNumberTownAddress ? (
              <AddressField>
                <Field
                  name="address.oldNumberTownAddress"
                  disabled
                  Icon={PinGray}
                  component={FormikInput}
                  width={`${(240 / 72) * 100}%`}
                />
                <Button
                  type="button"
                  height="42px"
                  variant="outline"
                  onClick={() =>
                    handleShowSearchModal(true, cpfEvents.onClickLocation)
                  }
                >
                  변경하기
                </Button>
              </AddressField>
            ) : (
              <Button
                type="button"
                height="42px"
                variant="outline"
                onClick={() =>
                  handleShowSearchModal(true, cpfEvents.onClickLocation)
                }
              >
                <StyledPin /> 거주 지역(동) 선택하기
              </Button>
            )}
            <Info>
              <InfoIcon />
              {values.address && values.address.oldNumberTownAddress
                ? "근무지 안내를 위해 ‘동’ 정보만 참고해요."
                : "지역을 알려주시면 가까운 근무지를 안내해 드려요."}
            </Info>
          </Fieldset>
          <Fieldset>
            <legend>조금 더 알려주시면, 채용이 더 빨라요</legend>
            <Section>
              <DriverLicenseSelect>
                <Select
                  name="driverLicenseType"
                  placeholder="운전면허 종류"
                  options={driverLicenses}
                  value={
                    driverLicenses.find(
                      (license) => license.value === values.driverLicenseType
                    ) || null
                  }
                  onChange={({ value }) => {
                    googleEventTracker(cpfEvents.onChangeLicense);
                    setFieldValue("driverLicenseType", value);
                  }}
                  isSearchable={false}
                  styles={customSelectStyles}
                  components={{ DropdownIndicator }}
                />
              </DriverLicenseSelect>
              <Field
                name="birthdayYear"
                placeholder="출생년도"
                component={FormikInput}
                numberFormat={(val) =>
                  val.length < 5 ? `${val}년` : `${val.substring(0, 4)}년`
                }
                onFocus={(e) => {
                  googleEventTracker(cpfEvents.onFocusBirthYear);
                  !e.target.value && (e.target.value = "년");
                }}
              />
            </Section>
            <WorkerDatePicker
              name="workStartDate"
              selected={
                values.workStartDate ? new Date(values.workStartDate) : null
              }
              onChange={(date) =>
                setFieldValue("workStartDate", format(date, "yyyy-MM-dd"))
              }
              onFocus={(e) => {
                googleEventTracker(cpfEvents.onFocusStartDate);
                e.target.readOnly = true;
              }}
              dateFormat="yyyy-MM-dd"
              placeholderText="방문 희망 날짜"
              className="date-picker__input"
              minDate={new Date()}
              fixedHeight
              withPortal
            />
            <Info>
              <InfoIcon />
              건강검진 및 채용 테스트를 진행할 날짜입니다. 나중에 변경 가능하니
              편하게 선택해 주세요.
            </Info>
          </Fieldset>
          <PrivacyPolicy>
            쿠팡그룹의 정책에 따라 입사지원 시 지원자의
            <br />
            개인정보를 받고 있습니다.
            <br />
            <Link to={`/tc${location.search}`}>
              자세히 보기 <ArrowRight />
            </Link>
          </PrivacyPolicy>
          <ButtonWrapper isAbsolute={!isMobile || !Boolean(focusedInput)}>
            <Button
              type="submit"
              onClick={() => {
                const keys = [];
                const fields = {
                  name: "Name",
                  phone: "Phone",
                  address: "Location",
                  driverLicenseType: "License",
                  birthdayYear: "Year of Birth",
                  workStartDate: "Starting Date",
                };
                const fieldKeys = Object.keys(fields);
                for (const [key, value] of Object.entries(values)) {
                  if (key === "address") {
                    if (Boolean(value.oldNumberAddress)) {
                      keys.push(fields[key]);
                    }
                    continue;
                  }
                  if (fieldKeys.includes(key) && Boolean(value)) {
                    keys.push(fields[key]);
                  }
                }
                googleEventTracker(
                  cpfEvents.onClickSubmitButton,
                  keys.length,
                  keys.join(",")
                );
              }}
              disabled={!(dirty && isValid)}
            >
              동의하고 지원 완료하기
            </Button>
          </ButtonWrapper>
          <ActionSheet
            isDraggable={false}
            isVisible={showAreaSearchModal}
            onClose={() =>
              handleShowSearchModal(false, cpfEvents.onCloseLocationDetailPage)
            }
            maxWidth={640}
          >
            <AddressSearch
              onAddressClick={(address) => {
                setFieldValue("address", address);
                handleShowSearchModal(false, cpfEvents.onSelectLocationDetail);
                const mainContainer = document.querySelector("main");
                mainContainer.scrollTo(0, mainContainer.scrollHeight);
              }}
              onClose={() =>
                handleShowSearchModal(
                  false,
                  cpfEvents.onCloseLocationDetailPage
                )
              }
            />
          </ActionSheet>
        </StyledForm>
      )}
    </Formik>
  );
};

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <ArrowDown />
  </components.DropdownIndicator>
);

const StyledForm = styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 28px 20px 98px;
`;

const Fieldset = styled.fieldset`
  padding: 0;
  border: 0;
  color: #222222;
  flex-shrink: 0;
  > legend {
    margin: 0;
    padding: 0;
    margin-bottom: 14px;
    font-size: 18px;
    font-weight: bold;
  }
  & + fieldset {
    margin-top: 34px;
  }
`;

const Section = styled.section`
  display: flex;
  margin-bottom: 12px;
  > div + div {
    margin-left: 8px;
  }
`;

const AddressField = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: 8px;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-size: 12px;
  line-height: 17px;
  color: #454f5b;
  svg {
    margin-right: 6px;
    min-width: 13px;
  }
`;

const StyledPin = styled(Pin)`
  display: flex;
  margin-right: 10px;
`;

const PrivacyPolicy = styled.section`
  margin-top: 24px;
  padding: 12px;
  border-radius: 4px;
  background: #f5f6f7;
  font-size: 14px;
  color: #555555;
  a {
    display: flex;
    align-items: center;
    margin-top: 4px;
    color: #2267ff;
    text-decoration: none;

    svg {
      margin-left: 2px;
    }
  }
`;

const ButtonWrapper = styled.div`
  position: ${({ isAbsolute }) => (isAbsolute ? "absolute" : "relative")};
  transition: bottom 0.3s ease-in-out 250ms;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  padding: ${({ isAbsolute }) => (isAbsolute ? "16px" : "30px 0")};
  background: ${({ isAbsolute }) =>
    isAbsolute
      ? css`linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.7) 0%,
    #ffffff 20.17%
  )`
      : "#ffffff"};
  box-shadow: 0px -2px 12px 1px #ffffff;
`;

const DriverLicenseSelect = styled.div`
  width: ${(180 / 132) * 100}%;
`;

const customSelectStyles = {
  control: () => ({
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 8,
    border: "solid 1px #C4CDD5",
    height: 42,
  }),
  valueContainer: () => ({
    position: "relative",
    flex: 1,
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
    paddingLeft: 14,
  }),
  placeholder: () => ({}),
  indicatorSeparator: () => ({}),
  option: (_, state) => ({
    padding: "6px",
    borderBottom: "1px solid #f6f6f6",
    backgroundColor: state.isSelected ? "#f6f6f6" : "transparent",
  }),
};

const WorkerDatePicker = styled(DatePicker)`
  width: calc(100% - 54px);
  padding: 10px 14px 10px 38px;
  border: 1px solid #c4cdd5;
  border-radius: 8px;
  font-size: 16px;
  background: url(${Calendar}) no-repeat 15px 10px;

  &::placeholder {
    color: #aab5c0;
  }
`;

export default ApplicantForm;
