import React, { useRef, useState } from "react";
import { useDebounce } from "react-use";
import styled from "styled-components/macro";

import errorHandler from "api/errorHandler";
import { ReactComponent as CloseIcon } from "assets/close-black.svg";
import { ReactComponent as SearchIcon } from "assets/search-black.svg";
import { ReactComponent as ClearIcon } from "assets/clear-icon.svg";
import { ReactComponent as ArrowDownIcon } from "assets/arrow-down.svg";

import { searchAddress } from "CPFRecruit/apis/applicantAPI";
import { cpfEvents, googleEventTracker } from "CPFRecruit/util/analytics";

const AddressSearch = ({ onAddressClick, onClose }) => {
  const inputRef = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [initLoading, setInitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [total, setTotal] = useState(0);
  const [nextPageNo, setNextPageNo] = useState(null);

  const loadAddressList = async (pageNo) => {
    try {
      setLoading(true);

      const { totalCount, hasNextPage, elements } = await searchAddress({
        keyword,
        pageNo,
        countPerPage: 10,
      });
      setLoading(false);
      setInitLoading(false);
      setTotal(totalCount);
      setNextPageNo(hasNextPage ? pageNo + 1 : null);
      setAddressList((prevItems) => [...prevItems, ...elements]);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const handleSearchAddress = async () => {
    if (keyword.length < 2) {
      return;
    }
    setTotal(0);
    setAddressList([]);
    loadAddressList(1);
  };

  const [, cancel] = useDebounce(handleSearchAddress, 500, [keyword]);

  const handleInputChange = (e) => {
    setInitLoading(true);
    setKeyword(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      cancel();
      handleSearchAddress();
      inputRef.current.blur();
    }
  };
  const handleReset = () => {
    setInitLoading(false);
    setKeyword("");
    setAddressList([]);
  };
  return (
    <Wrapper>
      <CloseButton aria-label="close-search" type="button" onClick={onClose}>
        <CloseIcon />
      </CloseButton>

      <AddressSearchSection>
        <h2>거주 지역 선택</h2>
        <InputWrapper>
          <SearchIcon />
          <input
            ref={inputRef}
            placeholder="도로명, 건물명, 번지를 검색하세요"
            value={keyword}
            onFocus={() => {
              googleEventTracker(cpfEvents.onClickLocationDetailField);
            }}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <ClearButton
            aria-label="clear-search"
            type="button"
            onMouseDown={(e) => {
              e.preventDefault();
              handleReset();
            }}
          >
            <ClearIcon />
          </ClearButton>
        </InputWrapper>
      </AddressSearchSection>

      {initLoading ? null : keyword.length < 1 ? (
        <TipArea>
          <TipTitle>지역 검색 Tip</TipTitle>
          아래의 조합으로 검색시 더욱 정확한 결과가 검색됩니다.
          <TipExample>
            도로명 + 건물번호 <br />
            <i>(예: 송파대로 570)</i> <br />
            <br />
            동/읍/면/리 + 번지 <br />
            <i>(예: 신천동 7-30)</i> <br />
            <br />
            건물명, 아파트명 <br />
            <i>(예: 반포자이아파트)</i>
          </TipExample>
        </TipArea>
      ) : addressList.length < 1 ? (
        <NoSearchResult>
          <strong>'{keyword}'에 대한 검색 결과가 없습니다.</strong>
        </NoSearchResult>
      ) : (
        <>
          <TotalCount>
            검색된 주소: <b>{total}</b>건
          </TotalCount>
          <AddressList>
            {addressList.map((address, index) => {
              const { roadAddress, oldNumberAddress, zipCode } = address;
              return (
                <AddressItem
                  data-testid={`addressItem_${index}`}
                  key={index}
                  onClick={() => {
                    handleReset();
                    onAddressClick(address);
                  }}
                >
                  <ZipCode>{zipCode}</ZipCode>

                  <AddressDescItem>
                    <dt>도로명</dt>
                    <dd>{roadAddress}</dd>
                  </AddressDescItem>

                  <AddressDescItem>
                    <dt>지번</dt>
                    <dd>{oldNumberAddress}</dd>
                  </AddressDescItem>
                </AddressItem>
              );
            })}
            {nextPageNo ? (
              <MoreBtn
                type="button"
                key="more-btn"
                disabled={loading}
                onClick={() => loadAddressList(nextPageNo)}
              >
                더보기
                <MoreIcon />
              </MoreBtn>
            ) : null}
          </AddressList>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 450px;
  background: #ffffff;
  padding-top: 20px;
  box-sizing: border-box;

  h2 {
    margin: 0 0 18px;
    font-weight: bold;
    font-size: 18px;
    color: #222222;
  }

  p {
    margin: 20px 0 0;
    font-size: 12px;
    color: #222222;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: 0;
  border: 0;
`;

const AddressSearchSection = styled.section`
  padding: 0 16px 14px;
  border-bottom: 1px solid #efefef;
  flex-shrink: 0;
`;

const ClearButton = styled.button`
  position: absolute;
  right: 10px;
  visibility: hidden;
  background: transparent;
  height: 16px;
  width: 16px;
  overflow: hidden;
  padding: 0;
  border: 0;
  outline: 0;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 36px;
  width: 100%;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 14px;

  svg {
    margin-right: 10px;
    flex-shrink: 0;
  }

  input {
    height: 100%;
    width: 100%;
    padding: 10px 28px 10px 0;
    box-sizing: border-box;
    outline: none;
    border: 0;
    caret-color: #346aff;
    font-size: 14px;
    color: #212b36;

    &:disabled {
      color: #454f5b;
      background: transparent;
    }

    &::placeholder {
      color: #aab5c0;
    }
  }

  &:focus-within {
    border: 1px solid #346aff;

    ${ClearButton} {
      visibility: visible;
    }
  }
`;

const TipArea = styled.div`
  padding: 20px 16px;
  font-size: 12px;
`;
const TipTitle = styled.h3`
  margin: 0 0 4px;
  font-size: 14px;
`;
const TipExample = styled.div`
  margin-top: 14px;
  i {
    font-style: normal;
    color: #666;
  }
`;

const NoSearchResult = styled.div`
  padding-top: 80px;
  text-align: center;
  font-size: 14px;
  color: #111111;
`;

const TotalCount = styled.div`
  padding: 8px 16px;
  font-size: 12px;
  color: #444;
`;

const AddressList = styled.ul`
  width: 100%;
  overflow: scroll;
  margin: 0;
  padding: 0;
`;

const AddressItem = styled.li`
  list-style: none;
  padding: 10px 16px;
  border-top: 1px solid #efefef;

  font-size: 14px;
  color: #222222;

  span {
    color: #33a3e2;
  }
`;

const ZipCode = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const AddressDescItem = styled.dl`
  display: flex;
  margin: 12px 0 10px;
  align-items: center;
  dt {
    display: inline-block;
    margin: 0 6px 0 0;
    padding: 3px 2px 2px;
    min-width: 32px;
    background: #f4f4f4;
    text-align: center;
    color: #666;
    font-size: 10px;
  }
  dd {
    margin: 0;
  }
`;

const MoreBtn = styled.button`
  width: 100%;
  padding: 12px;
  border: none;
  border-top: 1px solid #efefef;
  background: none;
  color: #346aff;
  font-size: 14px;
`;
const MoreIcon = styled(ArrowDownIcon)`
  margin-left: 6px;
  path {
    fill: currentColor;
  }
`;

export default AddressSearch;
