import styled from "styled-components/macro";

const Button = styled.button`
  height: ${({ height }) => height || "56px"};
  width: 100%;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: bold;
  text-align: center;

  outline: 0;
  border-radius: 8px;
  white-space: nowrap;
  ${({ variant }) =>
    variant === "outline"
      ? `
        border: 1px solid #3669F6;
        color: #3669F6;
        background-color: transparent;
      `
      : `
        border: 0;
        color: #ffffff;
        background-color: #346AFF;
      `}
  &:disabled {
    background-color: #cccccc;
  }
`;

export default Button;
